<script setup>
const props = defineProps({
  color: { type: String },
  image: { type: String },
  verified: { type: Boolean },
  size: { type: String },
  square: { type: Boolean },
  icon: { type: String },
  word: { type: String },
  borderminus: { type: Boolean },
});
// borderminus should true only on kudos case which has border on verify slot

const acronymWord = computed(() => {
  if (props.word && typeof props.word === "string") {
    if (props.word.split(" ").length === 1) {
      return props.word.slice(0, 2);
    } else {
      const initials = props.word
        .split(" ")
        .map((s) => s[0])
        .join("");
      return initials;
    }
  } else if (Array.isArray(props.word)) {
    const initials = props.word
      .filter((s) => typeof s === "string")
      .map((s) => s[0])
      .join("");
    return initials;
  } else {
    return "Invalid input";
  }
});

const iconSize = computed(() => {
  switch (props.size) {
    case "64":
      return 40;
    case "48":
      return 30;
    case "40":
      return 25;
    case "32":
      return 20;
    case "24":
      return 16;
    case "20":
      return 14;
    case "16":
      return 12;
  }
});

const fontSize = computed(() => {
  if (props.word.length == 1) {
    return parseInt(props.size) / 2;
  } else {
    switch (props.size) {
      case "64":
        return 24;
      case "48":
        return 20;
      case "40":
        return 16;
      case "32":
        return 12;
      case "24":
        return 10;
      case "20":
        return 8;
      case "16":
        return 6;
    }
  }
});
</script>
<template>
  <div
    id="chi-avatar"
    ref="avatarElement"
    :class="[
      props.word || props.icon ? 'word' : '',
      props.square ? 'square' : 'circle',
      props.color,
    ]"
    class="chi-avatar"
    :style="{ width: props.size + 'px', height: props.size + 'px' }"
  >
    <img
      v-if="props.image"
      :src="props.image"
      class="avatar"
      alt="avatar image"
      :class="props.square ? 'square' : 'circle'"
      :style="{ width: props.size + 'px', height: props.size + 'px' }"
    />
    <img
      v-if="props.icon"
      :src="props.icon"
      :style="{ width: iconSize + 'px', height: iconSize + 'px' }"
      class="avatar"
      alt="avatar icon"
      :class="props.square ? 'square' : 'circle'"
    />
    <span :class="[`text-style-tokens-avatar-${fontSize}`]" v-if="props.word">{{
      acronymWord
    }}</span>
    <div class="verify" :style="borderminus ? 'right:-2px; bottom:-2px' : ''">
      <slot style="display: block" name="verify" />
    </div>
    <!-- <BaseAvatarVerifiedBadge
      v-if="props.verified"
      class="verify"
      :size="parseInt(props.size) / 4"
    ></BaseAvatarVerifiedBadge> -->
  </div>
</template>
<style scoped lang="scss">
$colors: green, yellow, red, purple, blue;

.chi-avatar {
  outline: none;
  border: none;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  & .avatar {
    object-fit: cover;
    width: 100%;
    border-radius: 50%;
    height: auto;
  }
  & .verify {
    position: absolute;
    right: 0;
    bottom: 0;
    height: auto;
  }
  &.square {
    border-radius: 8px;
  }
  &.square {
    &.green {
      background: var(--color-positive-200-light-border);
      color: var(--color-positive-600-aa-text);
    }
    &.red {
      background: var(--color-critical-200-light-border);
      color: var(--color-critical-600-aa-text);
    }
    &.yellow {
      background: var(--color-warning-200-light-border);
      color: var(--color-warning-600-aa-text);
    }
    &.purple {
      background: var(--color-promote-200-light-border);
      color: var(--color-promote-600-aa-text);
    }

    &.blue {
      background: var(--color-primary-200-light-border);
      color: var(--color-primary-600-aa-text);
    }
  }
  &.circle {
    border-radius: 50%;
  }
  @each $color in $colors {
    &.circle.#{"" + $color} {
      &.green,
      &.red,
      &.yellow,
      &.purple,
      &.blue {
        background-color: var(
          --color-muted-testing-#{"" + $color}-200-light-border
        );
        color: var(--color-muted-testing-#{"" + $color}-600-aa-text);
      }
    }

    & .square {
      border-radius: 8px;
    }
    & .circle {
      border-radius: 50%;
    }
    &.word {
      background-color: var(--color-neutral-200-darker-bg-light-border);
    }
    & span {
      height: fit-content;
    }
  }
}
</style>
